import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { getLangsMenu } from "../utils/locale"
import styles from "./styles.module.scss"

export default function Page({ data, location }) {
  const postData = data.allContentfulBlogPost.edges[0].node
  const html = postData?.post?.raw && documentToReactComponents(JSON.parse(postData.post.raw))
  const general = data.allContentfulGeneralConfig.nodes[0]
  const { ka } = getLangsMenu(location.pathname)
  const linkToKa = ka.link
  const linkToEn = ka.link.slice(3)

  return (
    <div className={styles.singlePost}>
      <nav>
        <div>
          <Link to="/">
            <img src={general.logo.file.url} alt="logo" />
          </Link>
        </div>
        <ul>
          <li>
            <Link to={linkToEn}>en</Link>
          </li>
          <li>
            <Link to={linkToKa}>ge</Link>
          </li>
        </ul>
      </nav>
      <div className={styles.container}>
        <h1>{postData.title}</h1>
        {html}
      </div>
    </div>
  )
}

export const query = graphql`
  query($id: String!) {
    allContentfulBlogPost(
      filter: { node_locale: { eq: "en-US" }, contentful_id: { eq: $id } }
    ) {
      edges {
        node {
          id
          slug
          summary
          title
          more
          post {
            raw
          }
        }
      }
    }
    allContentfulGeneralConfig {
      nodes {
        logo {
          file {
            url
          }
        }
      }
    }
  }
`
